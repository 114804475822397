import React from "react";
import theme from "theme";
import { Theme, Box, Text, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy-policy"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header position="fixed" right="0px" left="0px">
			<Override slot="linkBox7" color="white" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box min-width="100px" min-height="100px" md-padding="120px 16px 120px 16px">
			<Box
				min-width="100px"
				min-height="100px"
				align-items="center"
				display="flex"
				flex-direction="column"
				justify-content="center"
				flex-wrap="no-wrap"
				grid-row-gap="48px"
				padding="100px 16px 100px 16px"
				margin="0px auto 0px auto"
				max-width="1348px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-items="flex-start"
					justify-content="center"
					grid-row-gap="16px"
				>
					<Text
						margin="0 0 0 0"
						word-wrap="break-word"
						text-fill-color="transparent"
						style={{
							"-webkit-text-fill-color": "transparent"
						}}
						background=" #080808"
						background-clip="text"
						font="--headingH0"
					>
						Privacy Policy
					</Text>
					<Text
						margin="0 0 0 0"
						word-wrap="break-word"
						text-fill-color="transparent"
						style={{
							"-webkit-text-fill-color": "transparent"
						}}
						background=" #080808"
						background-clip="text"
						font="--bodyText17SemiBold"
					/>
					<Text margin="0px 0px 0px 0px" text-align="justify" font="--bodyText21Regular">
						Please carefully review this policy, as it contains crucial information you should be aware of before proceeding to use our website, which is located at https://sphotonix.com, and any related subdomains (“WEBSITE”) as well as our other services (“SERVICES”). By continuing to use the Website, you are considered to have accepted this Privacy Policy.
						<br />
						<br />
						SPhotonix Inc., a Delaware corporation under registration number 11648059 and having its registered office at 254 Chapman Rd, Ste 208, #17030, 19702-5422, Newark, DE, USA and its affiliates (“we” or “us”) are committed to upholding your data privacy and safeguarding your information’s security and confidentiality.
						<br />
						<br />
						This Privacy Policy “Privacy Policy” explains how we gather, utilize, share, and safeguard the Personal Information you (“customer,” “you,” or “your”) provide during your interactions with us, both online and offline, in connection with the Website.
						<br />
						<br />
						As used in this Privacy Policy, “Personal Information” encompasses any data that can individually identify a person, including but not limited to name, email address, phone number, title, and other personally identifiable information.
						<br />
						<br />
						We act as the controller for the Personal Information you directly provide, collect, and/or process in accordance with this Privacy Policy. If you have inquiries about whether these conditions apply to you, please contact us using the information provided in the “Contact Us” section of our Website.
						<br />
						<br />
						Please be aware that this Privacy Policy may undergo periodic updates for reasons such as operational practices or regulatory adjustments. Therefore, we recommend reviewing our Privacy Policy whenever you return to our Website.
						<br />
						<br />
						This Policy does not pertain to the practices of companies we do not own or manage, or to individuals we do not employ or oversee.
						<br />
						<br />
						<br />
						COLLECTION OF PERSONAL INFORMATION
						<br />
						<br />
						This Privacy Policy pertains to the Personal Information we collect from you through our Website. We obtain Personal Information in two primary forms:
						<br />
						<br />
						Data supplied by you, such as during the registration process or contact form
						<br />
						<br />
						Information we automatically gather or collect as a result of your use of the Website.
						<br />
						<br />
						Personal Information provided by you: Certain interactions with the Website may necessitate you to disclose your Personal Information voluntarily. We may request you to supply specific details about yourself by completing and submitting online forms for marketing purposes, among other purposes.
						<br />
						<br />
						For instance, when submitting the Contact Us form, you will be required to provide the following information:
						<br />
						<br />
						Your name
						<br />
						<br />
						Your email address
						<br />
						<br />
						Your Phone number
						<br />
						<br />
						Company name
						<br />
						<br />
						Your role
						<br />
						<br />
						<br />
						INFORMATION THAT WE OBTAIN OR COLLECT AUTOMATICALLY FROM USE OF THE WEBSITE
						<br />
						<br />
						We may automatically collect certain information when you use the Website, including usage data, preferences, or other data related to your interactions with the Website.
						<br />
						<br />
						Specifically, our servers may automatically record certain information, and we may employ cookies (small text files stored on a device’s hard drive by a web service) or similar tracking technologies to collect the following types of information:
						<br />
						<br />
						Your browser type, language, plug-ins, Internet domain, device type, and operating system;
						<br />
						<br />
						Your Internet Protocol (IP) address;
						<br />
						<br />
						Your timezone;
						<br />
						<br />
						Your device screen resolution;
						<br />
						<br />
						Unique identifiers, such as mobile device identification numbers, which may assist in determining the physical location of such devices;
						<br />
						<br />
						Data linked to your interactions with Website content, including usage patterns and the type of content viewed or favored on the Website.
						<br />
						<br />
						Please note that you can configure most browsers to notify you when you receive a cookie, and you have the option to block cookies through your browser settings.
						<br />
						<br />
						Moreover, by utilizing our Website, network information, such as Website usage data, may be transmitted back to us. This information aids us in evaluating user interactions with our Website, enhancing its functionality, and addressing any potential issues.
						<br />
						<br />
						<br />
						COOKIES
						<br />
						<br />
						Our Website employs “cookies” to enhance your online experience. A cookie is a text file that a web page server places on your hard drive. Cookies cannot execute programs or deliver viruses to your computer. They are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie.
						<br />
						<br />
						We may use cookies to gather, store, and track information for statistical purposes related to the operation of our Website and Services. You have the option to accept or decline cookies. While most web browsers automatically accept cookies, you can typically adjust your browser settings to decline them. However, declining cookies may result in limited access to the features of the Website and Services. To learn more about cookies and how to manage them, visit internetcookies.org.
						<br />
						<br />
						<br />
						GEOLOCATION
						<br />
						<br />
						We collect location-based information, such as your country, region, and city, to optimize your connection to our data center. This information will only be shared with our data center provider for the sole purpose of delivering our service and Website to you.
						<br />
						<br />
						If you prefer not to share your information in this manner, you can opt out of location-based services at any time by adjusting your Internet-connected device settings or by contacting us using the information provided in the “Contact Us” section of our Website.
						<br />
						<br />
						<br />
						MANAGING PERSONAL INFORMATION
						<br />
						<br />
						You have the ability to access, add to, update, and delete specific Personal Information about yourself. The information you can view, update, and delete may evolve as the Website or Services change. When you update information, we may retain a copy of the previous information in our records. Some information may persist in our private records after you delete it from your account. We will retain and use your information as required to meet our legal obligations, resolve disputes, and enforce our agreements. We may utilize aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a way that identifies you personally. Once the retention period expires, Personal Information shall be deleted. Consequently, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after the retention period ends.
						<br />
						<br />
						<br />
						USE OF PERSONAL INFORMATION
						<br />
						<br />
						We utilize your Personal Information for the following purposes:
						<br />
						<br />
						Operating and improving the Website;
						<br />
						<br />
						Personalizing your Website experience;
						<br />
						<br />
						Delivering communications that may be of interest to you, whether electronically or otherwise;
						<br />
						<br />
						Enforcing this Privacy Policy;
						<br />
						<br />
						Enforcing terms and conditions/user agreements;
						<br />
						<br />
						Conducting market and traffic flow analyses and related reporting to third parties;
						<br />
						<br />
						Providing reports based on information collected from Website use;
						<br />
						<br />
						Keeping you informed about the latest announcements, software updates, upgrades, enhancements, special offers, and other information, with the option to modify your marketing preferences;
						<br />
						<br />
						Providing support and assistance with our Website;
						<br />
						<br />
						Verifying user identity;
						<br />
						<br />
						Providing shipping and billing information;
						<br />
						<br />
						Soliciting customer feedback;
						<br />
						<br />
						Supplying information related to Website use;
						<br />
						<br />
						Conducting questionnaires and surveys to enhance our products and services for customers and end-users. Your participation in any questionnaires is voluntary;
						<br />
						<br />
						Gaining a deeper understanding of your preferences to personalize newsletters and websites (e.g., enabling you to request specific information on products and services to be included in these newsletters);
						<br />
						<br />
						Marketing purposes;
						<br />
						<br />
						Other purposes disclosed to you at the time we collect your Personal Information.
						<br />
						<br />
						<br />
						PROCESSING AND SHARING YOUR PERSONAL INFORMATION
						<br />
						<br />
						By accessing or using the Website, or otherwise providing information to us, you consent to the processing and transfer of information to and from the U.S. and other countries and territories. These locations may have privacy laws that differ from your country of residence. In such cases, we ensure there are adequate safeguards in place to protect your Personal Information in compliance with our legal obligations. If the recipient is not part of our group of companies, an adequate safeguard may be provided through the signing of a data transfer agreement with the recipient, based on standard contractual clauses approved by the European Commission for personal data transfers to third countries
						<br />
						<br />
						<br />
						We may also disclose your Personal Information to third parties under the following circumstances:
						<br />
						<br />
						Vendors, Consultants, and Service Providers:
						<br />
						<br />
						We may share your Personal Information with third-party vendors, consultants, and service providers who work on our behalf and require access to your Personal Information to perform tasks such as billing processing and customer support. These service providers are authorized to use your Personal Information only as necessary to provide services to us.
						<br />
						<br />
						Compliance with Applicable Laws:
						<br />
						<br />
						We may disclose your Personal Information to a third party if we believe that such disclosure is reasonably necessary to comply with applicable laws, regulations, legal processes, or governmental requests, or to enforce our agreements, policies, and terms and conditions. We may also share your information to protect the security or integrity of our Website, safeguard ourselves, our customers, or the public from harm or illegal activities, or respond to emergencies where we believe in good faith that disclosing information is necessary to prevent harm or serious bodily injury to any person.
						<br />
						<br />
						Aggregated or Anonymized Data:
						<br />
						<br />
						We may also share aggregated or anonymized information with third parties that cannot be directly linked to you.
						<br />
						<br />
						<br />
						BILLING AND PAYMENTS
						<br />
						<br />
						We use third-party payment processors to securely process your payment information. The use of your Personal Information by these third-party processors is governed by their respective privacy policies, which may or may not provide privacy protections equivalent to this Privacy Policy. We recommend reviewing their privacy policies for further information.
						<br />
						<br />
						<br />
						SECURITY OF INFORMATION
						<br />
						<br />
						The Personal Information you provide to us is safeguarded by the password you create during your Website registration. It’s crucial to choose and protect your password appropriately to prevent unauthorized disclosure. Additionally, avoid sharing your password and prevent others from using your computer or mobile device.
						<br />
						<br />
						We have implemented reasonable administrative, technical, and physical security measures to protect your personal information against unauthorized access, destruction, or alteration. These measures include:
						<br />
						<br />
						SSL encryption (https) throughout interactions involving Personal Information.
						<br />
						<br />
						Encryption of databases.
						<br />
						<br />
						Hosting data on physically secure servers protected by firewalls.
						<br />
						<br />
						While we employ commercially reasonable security procedures to protect all collected Personal Information, please note that no security system is entirely foolproof. Therefore, we cannot guarantee the absolute security of the Website or the prevention of interception of your Personal Information during transmission to us. In the event of a security systems breach, we will either post a notice or attempt to notify affected individuals via email and take reasonable steps to address the breach.
						<br />
						<br />
						<br />
						DATA SUBJECT RIGHTS
						<br />
						<br />
						You have specific rights concerning your Personal Information, as detailed below. Please note that we may not always be able to fully fulfill your requests due to certain circumstances. In some cases, we may request additional information related to your request or verify your identity. Nonetheless, we will respond to inform you of our decision in such situations.
						<br />
						<br />
						Access: You can request a copy of the Personal Information we hold about you.
						<br />
						<br />
						Rectification: If you believe any Personal Information we hold about you is incorrect or incomplete, you can request corrections or updates. In some cases, you can make these changes directly from your account.
						<br />
						<br />
						Objection: You can contact us to express your objection to the collection or use of your Personal Information for certain purposes.
						<br />
						<br />
						Erasure: You can request the deletion of some or all of your Personal Information from our systems.
						<br />
						<br />
						Restriction of Processing: You can ask us to limit further processing of your Personal Information.
						<br />
						<br />
						Portability: You have the right to request a machine-readable copy of your Personal Information. You can also request the transfer of this data to another entity, where technically feasible.
						<br />
						<br />
						Withdrawal of Consent: If we process your Personal Information based on your consent, you have the right to withdraw that consent at any time.
						<br />
						<br />
						Right to File Complaint: You can lodge a complaint about our Personal Information practices with the relevant supervisory authority in your country or EU Member State.
						<br />
						<br />
						<br />
						CALIFORNIA PRIVACY RIGHTS:
						<br />
						<br />
						The California Consumer Privacy Act (“CCPA”) provides California consumers with specific rights regarding their Personal Information. You have the right to request businesses subject to the CCPA, including our affiliates and third parties for marketing purposes, to disclose certain information about their collection and use of your Personal Information over the past 12 months.
						<br />
						<br />
						Additionally, you can ask these businesses to delete Personal Information they have collected from you, with certain exceptions. If a business sells Personal Information, you have the right to opt out of such sales. Furthermore, businesses are prohibited from discriminating against you for exercising your CCPA rights.
						<br />
						<br />
						Please note that as we provide services to customers, we act as a “service provider” under the CCPA. Therefore, requests for access or deletion of your Personal Information under the CCPA should be directed using the contact details provided in the “Contact Us” section of our Website. Rest assured, we will not differentiate the quality of service or prices based on your CCPA rights.
						<br />
						<br />
						<br />
						ACCESS BY MINORS
						<br />
						<br />
						We do not knowingly collect or retain Personal Information from or about children under the age of 18. If you believe a child under 18 has provided us with their Personal Information, please have a parent or legal guardian contact us using the information in the “Contact Us” section of our Website to request the deletion of the child’s information from our records.
						<br />
						<br />
						<br />
						CHANGES TO THIS PRIVACY POLICY
						<br />
						<br />
						We periodically update this Privacy Policy to incorporate customer feedback and changes on our Website. When we make changes to this policy, we will revise the “last updated” date at the top. In cases of material changes to the policy or alterations in how we use Personal Information, we will notify you either by posting a notice before the changes take effect or by sending you a direct notification.
						<br />
						<br />
						<br />
						USER CONSENT
						<br />
						<br />
						By using our Website, you agree to the terms of this Privacy Policy and consent to our processing of Personal Information for the purposes mentioned herein, as well as those explained during data collection.
						<br />
						<br />
						RIGHT TO COMPLAIN
						<br />
						<br />
						If you are dissatisfied with our handling of your personal data or any privacy-related queries or requests you’ve made to us, you have the right to lodge a complaint with the EU Data Protection Authority (“DPA”) in your jurisdiction. To be directed to the appropriate DPA, please contact us using the information provided in the “Contact Us” section of our Website.
						<br />
						<br />
						DATA BREACH
						<br />
						<br />
						In the event that we become aware of a security breach on the Website or unauthorized disclosure of users’ Personal Information to unrelated third parties due to external activities such as security breaches or fraud, we reserve the right to take reasonable measures. These measures may include investigations, reporting, and cooperation with law enforcement authorities. If a data breach occurs and we believe there is a reasonable risk of harm to users or if notification is required by law, we will make reasonable efforts to notify affected individuals. Notifications will be posted on the Website, and emails will be sent to affected users.
						<br />
						<br />
						<br />
						ACCEPTANCE OF THIS POLICY
						<br />
						<br />
						By using the Website and its Services, you acknowledge that you have read and agree to all the terms and conditions outlined in this Privacy Policy. If you do not agree to abide by these terms, you are not authorized to use or access the Website and its Services.
						<br />
						<br />
						Contact Us:
						<br />
						<br />
						Should you have any questions about this Privacy Policy, please feel free to contact us at privacy@sphotonix.com
					</Text>
				</Box>
			</Box>
		</Box>
		<Box
			width="100%"
			padding-top="100px"
			padding-bottom="24px"
			padding-left="190px"
			padding-right="190px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="140px"
			display="flex"
			sm-display="flex"
			sm-padding="24px 16px 24px 16px"
			sm-height="min-content"
			sm-grid-row-gap="64px"
			quarkly-title="Footer"
			grid-row-gap="0px"
			grid-column-gap="0px"
			padding="0px 190px 24px 190px"
			md-padding="0px 16px 24px 16px"
		>
			<Box
				align-self="stretch"
				height="51px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				sm-display="flex"
				sm-grid-row-gap="32px"
				sm-height="auto"
			>
				<Box align-self="stretch" height="1px" background="#B1B1B1" width="100%" />
				<Box
					align-self="stretch"
					justify-content="space-between"
					align-items="center"
					grid-gap="32px"
					display="flex"
					sm-width="100%"
					sm-height="fit-content"
					sm-display="flex"
					sm-flex-direction="row"
					sm-flex-wrap="wrap"
					sm-align-content="space-around"
					sm-align-items="center"
					sm-justify-content="space-between"
					flex-direction="row"
					sm-grid-row-gap="16px"
				>
					<Text
						color="#212121"
						word-wrap="break-word"
						margin="0px 0px 0px 0px"
						font="--bodyText17Regular"
						sm-font="--bodyText15Regular"
					>
						©2024 SPhotonix. All rights reserved.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="row"
						flex-wrap="no-wrap"
						align-items="center"
						justify-content="center"
						grid-column-gap="32px"
						sm-order="-1"
						sm-display="flex"
						sm-grid-column-gap="16px"
					>
						<Text
							color="#212121"
							font="400 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							<Link href="/privacy-policy" color="inherit" text-decoration-line="initial">
								Privacy Policy
							</Link>
						</Text>
						<Text
							color="#212121"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							font="--bodyText17Regular"
							sm-font="--bodyText15Regular"
						>
							Cookies
						</Text>
					</Box>
				</Box>
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
		</RawHtml>
	</Theme>;
});